<template>
  <v-card class="white text-center" width="100%">
    <v-card-title
      class="justify-center text--secondary font-weight-regular text-uppercase"
    >
      {{ title }}
    </v-card-title>
    <v-card-text class="justify-center">
      <h1 class="primary--text">{{ dataNumber }}</h1>
    </v-card-text>

    <v-layout justify-center>
      <v-card-actions style="width: 90%">
        <!-- Here goes the graph / content -->
        <slot />
      </v-card-actions>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: 'AdminDataCard',

  props: {
    title: { type: String, required: true },
    dataNumber: { type: Number, default: 0 }
  }
}
</script>
